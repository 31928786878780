import React from 'react';
import styles from './503.module.sass';
import { Text } from '../../Text';
import { YaAndGaFooter } from '../../../lib/YaAndGaHelper';
import { Icon } from '../../Icon';
import { IconSVG } from '../../SpriteSVG';

export const Page503 = () => (
  <div className="container--block">
    <div className={styles.headerBlock}>
      <div>
        <Text variant={'heading-1'} color={'primaryColor'}>
          Сайт временно недоступен
        </Text>
      </div>
      <div>
        <Text variant={'text-16'}>
          Мы уже работаем над техническими проблемами, приносим свои извинения
          за неудобства.
        </Text>
      </div>
    </div>
    <div className={styles.instructionBlock}>
      <div>
        <div>
          <Text variant={'heading-9'}>1. Повторите вход через 10 минут</Text>
        </div>
        <div>
          <Text variant={'heading-9'}>
            2. Свяжитесь с нами по любому комфортному для вас каналу:
          </Text>
        </div>
      </div>
      <div className={styles.social}>
        <a
          target="_blank"
          href="https://wa.clck.bar/79258328975"
          rel="nofollow noreferrer"
          onClick={() => {
            YaAndGaFooter('click_whatsup_footer');
          }}
        >
          <Icon
            icon={<IconSVG name={'social/wa'} width={40} height={40} />}
            title="whatsapp"
          />
        </a>
        <a
          className={styles.socials}
          target="_blank"
          href="https://t.me/+UYjTvBXN7slkOTZi"
          rel="nofollow noreferrer"
        >
          <Icon
            icon={<IconSVG name={'social/tg'} width={40} height={40} />}
            title="telegram"
          />
        </a>
        <a
          className={styles.socials}
          href="https://vk.com/kruiz.online"
          target="_blank"
          rel="nofollow noreferrer"
          onClick={() => {
            YaAndGaFooter('click_vk_footer');
          }}
        >
          <Icon
            icon={<IconSVG name={'social/vk'} width={40} height={40} />}
            title="Вконтакте"
          />
        </a>
        <a
          className={styles.socials}
          href="https://ok.ru/group/52494082834611"
          target="_blank"
          rel="nofollow noreferrer"
        >
          <Icon
            icon={<IconSVG name={'social/ok'} width={40} height={40} />}
            title="ok"
          />
        </a>
        <a
          className={styles.socials}
          href="https://zen.yandex.ru/id/5cf7c5fd92015300af0beb57"
          target="_blank"
          rel="nofollow noreferrer"
        >
          <Icon
            icon={<IconSVG name={'social/dzen'} width={40} height={40} />}
            title="zen.yandex"
          />
        </a>
        {/* <a
          className={styles.socials}
          href="https://www.instagram.com/kruiz.online/"
          target="_blank"
          rel="nofollow noreferrer"
          onClick={() => {
            YaAndGaFooter('click_ig_footer');
          }}
        >
          <Icon
            icon={<IconSVG name={'social/inst'} width={40} height={40} />}
            title="instagram"
          />{' '}
          <div>*</div>
        </a> */}
      </div>
    </div>
  </div>
);
