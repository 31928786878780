import { logger } from './logger';
import { isJSON } from '../utils/isJSON';

export const interceptorSwitch = res => {
  switch (res.request.path) {
    case '/api/v1/ApiPopdir/apiPopular':
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'river',
                endpoint: '/ApiPopdir/apiPopular',
                typeEnv: 'ssr',
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'api popular cruise river received',
            })
          : logger({
              additionalFields: {
                type: 'river',
                endpoint: '/ApiPopdir/apiPopular',
                typeEnv: 'ssr',
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to get popular river cruises',
            })
      }`;
    case '/api/v1/ApiPopdir/getPopdirSlider':
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'all',
                endpoint: '/ApiPopdir/getPopdirSlider',
                typeEnv: 'ssr',
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'popdir slider data received',
            })
          : logger({
              additionalFields: {
                type: 'all',
                endpoint: '/ApiPopdir/getPopdirSlider',
                typeEnv: 'info',
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to load popdir slider',
            })
      }`;
    case `/api/v1/ApiPopdir/get_popdir?href=${res.data.href}`:
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'all',
                endpoint: '/ApiPopdir/get_popdir',
                typeEnv: 'ssr',
                popdir_id: `${res.data.href}`,
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'seo text popdir data received',
            })
          : logger({
              additionalFields: {
                type: 'all',
                endpoint: '/ApiPopdir/get_popdir',
                typeEnv: 'ssr',
                popdir_id: `${res.data.href}`,
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to load seo text popdir',
            })
      }`;
    case '/api/v1/ApiUsers/check_token/token':
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'all',
                endpoint: '/ApiUsers/check_token/token',
                typeEnv: 'ssr',
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'user information received',
            })
          : logger({
              additionalFields: {
                type: 'all',
                endpoint: '/ApiUsers/check_token/token',
                typeEnv: 'ssr',
                is_Not_JSON: true,
              },
              level: 'critical',
              message: 'Failed to get user information',
            })
      }`;
    case `/api/v1/ApiCruise/cruise_data/${res.data.cruise?.cruise_id}?is_sea=1`:
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'sea',
                endpoint: 'ApiCruise/cruise_data',
                typeEnv: 'ssr',
                id_sea_cruise: `${res.data.cruise?.cruise_id}`,
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'cruise sea data received',
            })
          : logger({
              additionalFields: {
                type: 'sea',
                endpoint: 'ApiCruise/cruise_data',
                typeEnv: 'ssr',
                id_sea_cruise: `${res.data.cruise?.cruise_id}`,
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to cruise sea data',
            })
      }`;
    case `/api/v1/Bron/LoadPlaces2/${res.request.path.split('/')[5]}`:
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'river',
                endpoint: 'Bron/LoadPlaces2',
                typeEnv: 'ssr',
                id_cruise: `${res.request.path.split('/')[5]}`,
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'cabin data received',
            })
          : logger({
              additionalFields: {
                type: 'river',
                endpoint: 'Bron/LoadPlaces2',
                typeEnv: 'ssr',
                id_cruise: `${res.request.path.split('/')[5]}`,
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to get cabin data',
            })
      }`;
    case `/api/v1/ApiCruise/cruise_data/${res.data.cruise?.cruise_id}`:
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'river',
                endpoint: 'ApiCruise/cruise_data',
                typeEnv: 'ssr',
                id_cruise: `${res.data.cruise?.cruise_id}`,
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'cruise data received',
            })
          : logger({
              additionalFields: {
                type: 'river',
                endpoint: 'ApiCruise/cruise_data',
                typeEnv: 'ssr',
                id_cruise: `${res.data.cruise?.cruise_id}`,
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to get data cruise data',
            })
      }`;
    case '/api/v1/ApiVendors/sendVendorsPage':
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'all',
                endpoint: 'ApiVendors/sendVendorsPage',
                typeEnv: 'ssr',
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'vendors data received',
            })
          : logger({
              additionalFields: {
                type: 'all',
                endpoint: 'ApiVendors/sendVendorsPage',
                typeEnv: 'ssr',
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to get vendors data',
            })
      }`;
    case '/api/v1/Booking2019/get_cruis_list/new_river':
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'river',
                endpoint: 'Booking2019/get_cruis_list/new_river',
                typeEnv: 'ssr',
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'river cruises data received',
            })
          : logger({
              additionalFields: {
                type: 'river',
                endpoint: 'Booking2019/get_cruis_list/new_river',
                typeEnv: 'ssr',
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to get river cruises data',
            })
      }`;
    case '/api/v1/ApiBanners/getHomepageBanners?is_sea=1':
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'sea',
                endpoint: 'ApiBanners/getHomepageBanners?is_sea=1',
                typeEnv: 'ssr',
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'sea banners data received',
            })
          : logger({
              additionalFields: {
                type: 'sea',
                endpoint: 'ApiBanners/getHomepageBanners?is_sea=1',
                typeEnv: 'ssr',
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to get data sea banners',
            })
      }`;
    case '/api/v1/ApiPopdir/apiPopularSea':
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'sea',
                endpoint: 'ApiPopdir/apiPopularSea',
                typeEnv: 'ssr',
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'api popular cruise sea received',
            })
          : logger({
              additionalFields: {
                type: 'sea',
                endpoint: 'ApiPopdir/apiPopularSea',
                typeEnv: 'ssr',
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to get popular sea cruises',
            })
      }`;
    case '/api/v1/ApiReviews/main_reviews?dbg=1':
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'all',
                endpoint: '/ApiReviews/main_reviews?dbg=1',
                typeEnv: 'ssr',
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'main reviews data received',
            })
          : logger({
              additionalFields: {
                type: 'all',
                endpoint: '/ApiReviews/main_reviews?dbg=1',
                typeEnv: 'ssr',
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'Failed to get main review data',
            })
      }`;
    case `/api/v1/ApiShips/reviews/${res.request.path.split('/')[5]}`:
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'river',
                endpoint: 'ApiShips/reviews',
                typeEnv: 'ssr',
                id_review_ship: `${res.request.path.split('/')[5]}`,
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'review ship data received',
            })
          : logger({
              additionalFields: {
                type: 'river',
                endpoint: 'ApiShips/reviews',
                typeEnv: 'ssr',
                id_review_ship: `${res.request.path.split('/')[5]}`,
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to get data review ship',
            })
      }`;
    case '/api/v1/Booking2019/get_cruis_list/sea':
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'sea',
                endpoint: 'Booking2019/get_cruis_list/sea',
                typeEnv: 'ssr',
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'cruises sea data received',
            })
          : logger({
              additionalFields: {
                type: 'sea',
                endpoint: 'Booking2019/get_cruis_list/sea',
                typeEnv: 'ssr',
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to load sea cruises data',
            })
      }`;
    case '/api/v1/ApiPopdir/getPopdirPage':
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'river',
                endpoint: '/ApiPopdir/getPopdirPage',
                typeEnv: 'ssr',
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'popdir cities data received',
            })
          : logger({
              additionalFields: {
                type: 'river',
                endpoint: '/ApiPopdir/getPopdirPage',
                typeEnv: 'ssr',
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to load popdir cities',
            })
      }`;
    case `/api/v1/ApiShips/newShipsScheme/${res.data.ship_id}`:
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'all',
                endpoint: 'ApiShips/newShipsScheme',
                typeEnv: 'ssr',
                ship_id: `${res.data.ship_id}`,
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'shema received',
            })
          : logger({
              additionalFields: {
                type: 'all',
                endpoint: 'ApiShips/newShipsScheme',
                typeEnv: 'ssr',
                ship_id: `${res.data.ship_id}`,
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to load shema',
            })
      }`;
    case `/api/v1/ApiCruise/getLikeCruises/${res.request.path.split('/')[5]}`:
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'river',
                endpoint: 'ApiCruise/getLikeCruises',
                typeEnv: 'ssr',
                id_like_cruise: `${res.request.path.split('/')[5]}`,
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'similar cruises data received',
            })
          : logger({
              additionalFields: {
                type: 'river',
                endpoint: 'ApiCruise/getLikeCruises',
                typeEnv: 'ssr',
                id_like_cruise: `${res.request.path.split('/')[5]}`,
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to get data similar cruises',
            })
      }`;
    case '/api/v1/ApiBanners/getHomepageBanners?is_sea=0':
      return `${
        res.status === 200 && isJSON(JSON.stringify(res.data))
          ? logger({
              additionalFields: {
                type: 'river',
                endpoint: 'ApiBanners/getHomepageBanners?is_sea=0',
                typeEnv: 'ssr',
                request_id: `${res.config.headers.request_id}`,
              },
              level: 'info',
              message: 'sea banners data received',
            })
          : logger({
              additionalFields: {
                type: 'river',
                endpoint: 'ApiBanners/getHomepageBanners?is_sea=0',
                typeEnv: 'ssr',
                is_Not_JSON: true,
              },
              level: 'error',
              message: 'failed to get sea data banners',
            })
      }`;
    default:
      return 'switch case did not work';
  }
};
