import React from 'react';
import Link from 'next/link';
import classcat from 'classcat';
import { useRouter } from 'next/router';
import { Anchor } from '../../Text';
import styles from './swithTypePage.module.sass';

export const SwithTypePage = () => {
  const router = useRouter();

  return (
    <div className={styles.wrapper}>
      <Link href="/">
        <Anchor
          className={classcat({
            [styles.link]: true,
            [styles.linkRiver]: true,
            [styles.linkActive]: router.pathname === '/',
          })}
        >
          Реки России
        </Anchor>
      </Link>
      <Link href="/sea">
        <Anchor
          className={classcat({
            [styles.link]: true,
            [styles.linkSea]: true,
            [styles.linkActive]: router.pathname === '/sea',
          })}
        >
          Морские круизы
        </Anchor>
      </Link>
    </div>
  );
};
