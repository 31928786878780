import { oldApi } from '../lib/api';

export class MyCabinetApi {
  async getUserOrders(token) {
    return await oldApi.get(`/ApiOrders/getUserOrders/${token}`);
  }

  // ${token}
  async getDetailOrder(token, orderId) {
    return await oldApi.get(`/ApiOrders/getUserOrder/${token}/${orderId}`);
  }

  async sendYmClientId(user_id, ym_cid) {
    return await oldApi.post(`/ApiOrders/saveClientIdOnOrderPage`, {
      user_id,
      ym_cid,
    });
  }

  async editUserOrder(
    token,
    userId,
    {
      name,
      surname,
      patronymic,
      sex,
      birthday,
      pasport_n,
      pasport_organ,
      pasport_date,
      residential_address,
    },
  ) {
    return await oldApi.post(
      `/ApiPassengers/updatePassenger/${token}/${userId || ''}`,
      {
        name,
        surname,
        patronymic,
        sex,
        birthday,
        pasport_n,
        pasport_organ,
        pasport_date,
        residential_address,
      },
    );
  }

  async getOrderDocuments(token, orderId) {
    return await oldApi.get(`/ApiOrders/getOrderDocuments/${token}/${orderId}`);
  }

  async genOrderDocuments(orderId) {
    return await oldApi.get(`Bron/pdf_gen/${orderId}`);
  }

  async getOrderPayLinks(orderId, date, prepayDays) {
    return await oldApi.get(
      `/ApiOrders/getOrderPaylinks/${orderId}/${date}/${prepayDays}`,
    );
  }

  async getUserPassengers(token) {
    return await oldApi.get(`/ApiOrders/getUserPassengers/${token}`);
  }

  async getOrderPassengers(token, orderId) {
    return await oldApi.get(
      `/ApiOrders/getOrderPassengers/${token}/${orderId}`,
    );
  }

  async createPassenger(
    token,
    {
      name,
      surname,
      patronymic,
      sex,
      birthday,
      pasport_n,
      pasport_organ,
      pasport_date,
      residential_address,
    },
  ) {
    return await oldApi.post(`/ApiOrders/createPassenger/${token}`, {
      name,
      surname,
      patronymic,
      sex,
      birthday,
      pasport_n,
      pasport_organ,
      pasport_date,
      residential_address,
    });
  }

  async editUserPassengers(
    token,
    id,
    {
      name,
      surname,
      patronymic,
      sex,
      birthday,
      pasport_n,
      pasport_organ,
      pasport_date,
      residential_address,
    },
  ) {
    return await oldApi.post(`/ApiPassengers/updatePassenger/${token}/${id}`, {
      name,
      surname,
      patronymic,
      sex,
      birthday,
      pasport_n,
      pasport_organ,
      pasport_date,
      residential_address,
    });
  }

  async deleteUserPassenger(token, id) {
    return await oldApi.post(`/ApiPassengers/deletePassenger/${token}/${id}`);
  }

  async getRosneftCard(number, id) {
    return await oldApi.post(`/ApiOrders/applyRNcard/${id}/${number}`);
  }

  async submitPromoCode(id, promo) {
    return await oldApi.post(
      `/ApiPromo/apply_promo?order_id=${id}&promocode=${promo}`,
    );
  }

  async getUserInfo(token) {
    return await oldApi.get(`/ApiUsers/getUser/${token}`);
  }

  async editUserInfo(
    token,
    id,
    {
      name,
      surname,
      patronymic,
      sex,
      birthday,
      phone,
      email,
      passp_number,
      passp_vidan,
      passp_date,
    },
  ) {
    return await oldApi.post(`/ApiUsers/updateUser/${token}/${id}`, {
      name,
      surname,
      patronymic,
      sex,
      birthday,
      phone,
      email,
      passp_number,
      passp_vidan,
      passp_date,
    });
  }

  getQR(order_id, amount, data_type, pay_percent) {
    return oldApi.post(`/Tinkoff_payment/getQr/`, {
      order_id,
      amount: Number(amount) * 100,
      data_type,
      pay_percent,
    });
  }

  async sbpChecked(value) {
    return await oldApi.post('/Tinkoff_payment/getQrState', {
      id_tinkoff: value,
    });
  }
}
