import React from 'react';
import { useFormikContext } from 'formik';
import { If, Then } from 'react-if';
import styles from './calculatetourists.module.sass';
import { DropDown } from '../DropDown';
import { Counter } from './components/Counter';
import { ChooseYear } from './components/ChooseYear';
import { declOfNum } from '../../utils/declOfNum';
import { YaAndGaSearchLine } from '../../lib/YaAndGaHelper';

const Types = {
  Adults: 'adults',
  Children: 'children',
};

Object.freeze(Types);

const childNumber = ['Первый', 'Второй', 'Третий', 'Четвертый'];

const touristsToStr = value => {
  const { adults, children } = value;

  let str = '';

  if (+adults > 0) {
    str += `${adults} ${declOfNum(adults, ['взрослый', 'взрослых'])}`;
  }

  if (+children === 1) {
    str += ', ребёнок';
  }

  if (+children > 1) {
    str += `, ${children} ребёнка`;
  }

  return str;
};

export const CalculateTourists = ({
  id,
  label,
  value,
  limit,
  last,
  disabled,
  loading,
  view,
  firstLoading,
  onCloseAction,
  actionOnClick,
  actionOnChildren,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const sumTourists =
    value.passengers[Types.Children] + value.passengers[Types.Adults];
  const isCanPlus = sumTourists < limit;

  const isAdultsMinusDisabled = value.passengers[Types.Adults] < 1;
  const isChildMinusDisabled = value.passengers[Types.Children] < 1;

  const plus = key => {
    if (isCanPlus) {
      setFieldValue(`value.passengers.${key}`, ++value.passengers[key]);
      if (actionOnClick) {
        actionOnClick();
      }
    }
  };

  const plusChildren = () => {
    plus(Types.Children);
    if (isCanPlus) value.passengers.years.push(1);
  };

  const minus = key => {
    if (value.passengers[key] > 0) {
      setFieldValue(`value.passengers.${key}`, --value.passengers[key]);
      if (actionOnClick) {
        actionOnClick();
      }
    }
  };

  const minusChildren = () => {
    minus(Types.Children);
    value.passengers.years.pop();
  };

  const content = (
    <>
      <Counter
        text="18 лет и старше"
        value={value.passengers[Types.Adults]}
        plus={() => {
          YaAndGaSearchLine('click_plus_field_adults');
          window.ym(
            35441960,
            'reachGoal',
            'main_search_specify_adult_or_child_in_tourists_field',
          );
          plus(Types.Adults);
        }}
        minus={() => {
          YaAndGaSearchLine('click_minus_field_adults');
          minus(Types.Adults);
        }}
        disabledPlus={!isCanPlus || loading}
        disabledMinus={isAdultsMinusDisabled || loading}
        view={view}
      />
      <Counter
        text="от 1 до 17 лет"
        value={value.passengers[Types.Children]}
        plus={() => {
          YaAndGaSearchLine('click_plus_field_childs');
          window.ym(
            35441960,
            'reachGoal',
            'main_search_specify_adult_or_child_in_tourists_field',
          );
          if (value.passengers[Types.Adults] > 0) {
            plusChildren();
          }
        }}
        minus={() => {
          YaAndGaSearchLine('click_minus_field_childs');
          minusChildren();
        }}
        disabledPlus={
          !isCanPlus || loading || value.passengers[Types.Adults] < 1
        }
        disabledMinus={isChildMinusDisabled || loading}
        view={view}
      />
      <If condition={!!value.passengers[Types.Children]}>
        <Then>
          <div className={styles.years}>
            {[...Array(value.passengers[Types.Children] || 0)].map((_, i) => (
              <ChooseYear
                key={i}
                actionOnChildren={actionOnChildren}
                id={`passengers.years[${i}]`}
                text={i === 0 ? 'Ребёнок' : `${childNumber[i]} ребёнок`}
              />
            ))}
          </div>
        </Then>
      </If>
    </>
  );
  return (
    <DropDown
      firstLoading={firstLoading}
      label={label}
      onCloseAction={onCloseAction}
      value={sumTourists > 0 ? touristsToStr(value.passengers) : 'Не выбрано'}
      isBodyRight={view !== 'admin'}
      id={id}
      content={content}
      last={last}
      disabled={disabled}
      view={view}
      {...props}
    />
  );
};

CalculateTourists.defaultProps = {
  limit: 4,
};
