import React from 'react';
import styles from './checkboxlist.module.sass';
import { Checkbox } from '../../../Checkbox';
import { YaAndGaSearchLine } from '../../../../lib/YaAndGaHelper';

export const CheckboxList = ({
  name,
  items,
  view,
  disabled,
  loading,
  actionOnClick,
}) => {
  if (!(items && Array.isArray(items) && items.length > 0)) {
    return null;
  }
  return (
    <div className={styles.checkboxes}>
      {items.map((i, index) => (
        <Checkbox
          key={index}
          name={name}
          value={i}
          view={view}
          disabled={disabled}
          loading={loading}
          onClick={() => {
            if (actionOnClick) {
              actionOnClick();
            }
            YaAndGaSearchLine(
              name === 'departure'
                ? 'choose_city_departure'
                : 'choose_city_arrival',
            );
            window.ym(
              35441960,
              'reachGoal',
              name === 'departure'
                ? 'main_search_select_city_in_departure_city_field'
                : 'main_search_select_destination_in_destination_field',
            );
          }}
        >
          {i}
        </Checkbox>
      ))}
    </div>
  );
};

CheckboxList.defaultProps = {
  view: 'main',
  disabled: false,
};
