import React from 'react';
import dayjs from 'dayjs';
import { Text } from '../Text';
import styles from './SearchInfo.module.sass';
import { scrollToUtil } from '../../utils/scrollToUtil';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';

export const SearchInfo = ({
  start_cities,
  visit_cities,
  cruise_dates,
  cruise_capacity,
  children_capacity,
  cruise_length,
  innerRef,
  isMobile,
}) => {
  const headerHeight = isMobile ? 124 : 144;
  const allDate = cruise_dates?.split(',');
  const scrollTo = scrollToUtil(headerHeight);

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const updateLocale = require('dayjs/plugin/updateLocale');
  dayjs.extend(updateLocale);

  dayjs.updateLocale('ru', {
    monthsShort: [
      'янв.',
      'фев.',
      'мар.',
      'апр.',
      'мая',
      'июн.',
      'июл.',
      'авг.',
      'сен.',
      'окт.',
      'ноя.',
      'дек.',
    ],
  });

  const getDate = date => {
    if (date) {
      const startDate = dayjs(date[0]).format('DD MMM YYYY');
      const endDate = dayjs(date[1]).format('DD MMM YYYY');
      const startMonth = dayjs(date[0]).format('MMM');
      const endMonth = dayjs(date[1]).format('MMM');
      const startDay = dayjs(date[0]).format('DD');
      const endDay = dayjs(date[1]).format('DD');
      const year = dayjs(date[0]).format('YYYY');

      if (startDate === endDate) {
        return `c ${startDate}`;
      }
      if (startMonth === endMonth) {
        return `c ${startDay} - ${endDay} ${startMonth} ${year}`;
      }
      return `с ${startDate} по ${endDate} `;
    }
    return '';
  };

  const getCruiseLength = cruiseLength => {
    if (cruiseLength.includes('4')) {
      return 'более 12';
    }
    if (cruiseLength.includes('3') && cruiseLength.includes('2')) {
      return 'до 12';
    }
    if (cruiseLength.includes('3')) {
      return 'на 9-12';
    }
    if (cruiseLength.includes('2') && cruiseLength.includes('1')) {
      return 'до 8';
    }
    if (cruiseLength.includes('2')) {
      return 'на 4-8';
    }
    if (cruiseLength.includes('1')) {
      return 'до 3х';
    }
    return '';
  };

  return (
    <>
      <div role="button" aria-hidden onClick={() => scrollTo(innerRef)}>
        <div className={isMobile ? styles.searchInfo : styles.infoDesktop}>
          <Text component="h1">
            {start_cities || 'Отправление'}
            {<span> → </span>}
            {visit_cities || 'Направление'}
          </Text>
          <Text component="p" variant="">
            {getDate(allDate) || 'Любые даты'}
            {cruise_length ? `  ${getCruiseLength(cruise_length)} дней` : ''}
          </Text>
          <div className={styles.iconWrapper}>
            {cruise_capacity !== undefined ? (
              <Text component="p" variant="">
                {cruise_capacity}{' '}
                {Number(cruise_capacity) === 1 ? 'взрослый' : 'взрослых'}
                {children_capacity ? ' и ' : ''}
                {children_capacity && Number(children_capacity) > 1
                  ? ` ${children_capacity} ребёнка`
                  : ''}
                {children_capacity && Number(children_capacity) === 1
                  ? ` ${children_capacity} ребёнок`
                  : ''}
              </Text>
            ) : (
              <Text component="p" variant="">
                Не выбрано
              </Text>
            )}
            {!isMobile ? (
              <Icon
                icon={<IconSVG name={'common/back'} width={9} height={17} />}
                className={styles.arrow}
              />
            ) : null}
          </div>

          <Text className={styles.button}>Изменить</Text>
        </div>
      </div>
    </>
  );
};
