/* eslint-disable no-dupe-class-members */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
// @ts-nocheck
import { action, makeObservable, observable, runInAction } from 'mobx';
import debounce from 'lodash/debounce';
import Router from 'next/router';
import { isClient } from '@/utils/isClient';
import { CruiseType } from '@/typings/cruise';

export interface ISearchStore<T> {
  hydrate?(data: any): void;
  page: number;
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export class Search<T> implements ISearchStore<T> {
  api: any;
  data: any;
  page: number;
  isLoading: boolean;
  isFirstLoading: boolean;
  paginatePage;
  page;
  filters;
  amount;
  params: any;
  cards;
  pageCount;
  newCards;
  isSetUrlParams;
  paramsFilters;
  extraCondition: string;
  abortController: any;
  isAbort: boolean;
  emptyList: boolean;
  loadedList: boolean;
  setParamsFilters(): void;
  setFilters(): void;
  updateFilters(): void;
  removeBlankProp(): void;
  convertFiltersToUrl(): void;
  setUrlParams(): void;
  setParamsFromUrl(): void;
  setAmount(): void;
  onMore(): void;
  setCadrs(): void;
  setExtraCondition(): void;
  setCountPage(): void;
  pageTo(): void;
  triggerSearch(): void;

  constructor(root: any, api: any) {
    this.page = 1;
    this.amount = 0;
    this.data = [];
    this.newCards = [];
    this.cards = [];
    this.extraCondition = '';
    this.isSetUrlParams = false;
    this.isLoading = false;
    this.isFirstLoading = true;
    this.countPage = false;
    this.abortController = isClient ? new AbortController() : null;
    this.paramsFilters = {};
    this.pageCount = 1;
    this.isAbort = false;
    makeObservable(this, {
      load: action,
      setAmount: action,
      removeBlankProp: action,
      setFilters: action,
      updateFilters: action,
      convertFiltersToUrl: action,
      onMore: action,
      setUrlParams: action,
      setParamsFromUrl: action,
      setCadrs: action,
      setExtraCondition: action,
      setCountPage: action,
      pageTo: action,
      triggerSearch: action,
      paramsFilters: observable,
      isAbort: observable,
      pageCount: observable,
      amount: observable,
      extraCondition: observable,
      isLoading: observable,
      isFirstLoading: observable,
      newCards: observable,
      data: observable,
      abortController: observable,
      cards: observable,
      isSetUrlParams: observable,
      page: observable,
    });
  }

  removeBlankProp(obj: URL) {
    return Object.entries(obj)
      .filter(([, v]) => v !== '')
      .filter(([, v]) => v !== 0)
      .filter(([, v]) => v !== '0')
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  setFilters(filters) {
    // this.clearFilters();
    this.filters = { ...this.filters, ...filters };
    // if (this.isSetUrlParams) {
    //   this.setParamsFilters();
    //   this.paramsToUrl();
    // }
  }

  setUrlParams() {
    this.isSetUrlParams = true;
  }

  async paramsToUrl() {
    const { push, pathname, query } = Router;

    await push(
      {
        pathname,
        query: {
          ...query,
          ...this.removeBlankProp(this.params),
          data: new URLSearchParams(this.paramsFilters).toString() || '',
        },
      },
      undefined,
      { scroll: false, shallow: true },
    );
    this.paramsFilters.search_page = 1;
  }

  get method() {
    return {
      [CruiseType.RIVER]: 'getList',
      [CruiseType.SEA]: 'getSeaList',
    };
  }

  setParamsFilters() {}

  setExtraCondition() {
    this.paramsFilters.extraCondition = this.extraCondition || 'new_main';
  }

  setParamsFromUrl() {
    if (Router.query.data) {
      this.paramsFilters = Object.fromEntries(
        new URLSearchParams(Router.query.data),
      );
    }
  }
  setLoading() {
    this.isFirstLoading = false;
  }
  updateFilters() {}

  async fetch() {
    this.setExtraCondition();
    if (isClient) {
      if (this.isAbort) {
        if (this.abortController) {
          this.abortController.abort();
          this.abortController = new AbortController();
        }
      }
    }

    this.isLoading = true;
    try {
      const { data } = await this.api[this.method[this.filters.type]](
        this.paramsFilters,
        isClient ? this.abortController.signal : null,
      );
      this.isLoading = true;
      runInAction(() => {
        this.isAbort = true;
        this.data = data;
        this.isLoading = true;
        this.isFirstLoading = false;
        this.updateFilters();
        this.setAmount();
        this.setCountPage();
        this.setNewCards();
        this.setCadrs();
      });
    } catch (error) {
      console.log(error, 'error');
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.isFirstLoading = false;
      });
    }
  }

  convertFiltersToUrl(filters) {}

  removeBlankProp(obj) {
    if (!obj) return '';
    return Object.entries(obj)
      .filter(([, v]) => v !== '')
      .filter(([, v]) => v !== 0)
      .filter(([, v]) => v !== '0')
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  get params() {
    return this.convertFiltersToUrl(this.filters);
  }

  setAmount() {
    if (Object.prototype.hasOwnProperty.call(this.data.cruises, 'amount')) {
      this.amount = this.data.cruises.amount;
    }
  }

  setCountPage() {
    if (this.data?.cruises?.page_count) {
      this.pageCount = this.data.cruises.page_count;
    }
  }

  setCadrs() {
    this.cards = [...this.cards, ...this.newCards];
  }

  setNewCards() {
    if (this.data?.cruises?.cruises_data) {
      this.isLoading = true;
      this.newCards = this.data?.cruises?.cruises_data;
    }
  }

  load() {
    runInAction(() => {
      // this.setParamsFilters();
      debounce(async () => await this.fetch(), 300)();
    });
  }

  triggerSearch() {
    setTimeout(() => {
      this.setParamsFilters();
      this.paramsToUrl();
      this.load();
    }, 0);
  }

  async onMore() {
    this.page += 1;
    this.paramsFilters.search_page = this.page;
    await this.fetch();
  }

  async pageTo(page) {
    this.page = page;
    this.paramsFilters.search_page = this.page;
    await this.fetch();
  }

  get hasQuery() {
    return isClient && window.location.search.includes('?');
  }

  get noResult() {
    return this.cards.length === 0 && this.isFirstLoading;
  }

  get loadedList() {
    return this.cards.length !== 0;
  }

  get emptyList() {
    return this.isLoading && this.cards.length === 0;
  }

  clearFilters() {
    this.cards = [];
    this.page = 1;
    this.paramsFilters.search_page = 1;
  }
}
