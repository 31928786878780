import React from 'react';
import cc from 'classcat';
import styles from './counter.module.sass';

export const Counter = ({
  text,
  value,
  plus,
  disabledMinus,
  disabledPlus,
  minus,
  view,
}) => (
  <div className={cc([styles.wrapper, styles[view]])}>
    <p className={styles.text}>{text}</p>
    <div className={styles.main}>
      <button
        type="button"
        className={styles.btn}
        onClick={minus}
        disabled={disabledMinus}
      >
        -
      </button>
      <span className={styles.num}>{value}</span>
      <button
        type="button"
        className={cc([styles.btn, styles.plus])}
        onClick={plus}
        disabled={disabledPlus}
      >
        +
      </button>
    </div>
  </div>
);

Counter.defaultProps = {
  disabledMinus: false,
  disabledPlus: false,
};
