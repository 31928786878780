import React from 'react';
import styles from './dayCruise.module.sass';
import { DropDown } from '../DropDown';
import { Checkbox } from '../Checkbox';
import { YaAndGaSearchLine } from '../../lib/YaAndGaHelper';

export const DayCruise = ({
  value,
  id,
  placeholder,
  label,
  error,
  loading,
  disabled,
  view,
  rangeDays = {},
  firstLoading,
  onCloseAction,
  onClearButtonClick,
  actionOnClick,
  ...props
}) => {
  const humanizationPeriod = val => {
    const data = Object.values(rangeDays);
    const selectedRanges = data.filter(({ range_index }) =>
      val.includes(String(range_index)),
    );

    const minRange = Math.min(...selectedRanges.map(i => i.startRange));
    const maxRange = Math.max(...selectedRanges.map(i => i.endRange));

    if (val.includes('4')) {
      return 'более 12 дней';
    }

    if (selectedRanges.length === 1) {
      return selectedRanges[0].alias;
    }

    if (minRange === 1) {
      return `До ${maxRange} дней`;
    }

    if (minRange === 4 || maxRange === 12) {
      return `4-12 дней`;
    }

    return '';
  };

  return (
    <DropDown
      firstLoading={firstLoading}
      id={id}
      value={humanizationPeriod(value)}
      placeholder={placeholder}
      label={label}
      error={error}
      disabled={disabled}
      view={view}
      onCloseAction={onCloseAction}
      onClearButtonClick={onClearButtonClick}
      {...props}
      content={
        <div className={styles.checkboxes}>
          {Object.values(rangeDays).map(days => (
            <Checkbox
              key={days.alias}
              name={id}
              disabled={loading}
              value={`${days.range_index}`}
              view={view}
              onClick={event => {
                if (actionOnClick) {
                  actionOnClick();
                }
                if (event.currentTarget.value === '1') {
                  YaAndGaSearchLine('choose_before_3_days_in_cruise');
                }

                if (event.currentTarget.value === '2') {
                  YaAndGaSearchLine('choose_between_4_from_8_days_in_cruise');
                }

                if (event.currentTarget.value === '3') {
                  YaAndGaSearchLine('choose_between_9_from_12_days_in_cruise');
                }

                if (event.currentTarget.value === '4') {
                  YaAndGaSearchLine('choose_more_12_days_in_cruise');
                }
                window.ym(
                  35441960,
                  'reachGoal',
                  'main_search_select_number_of_days_in_days_in_cruise_field',
                );
              }}
            >
              {days.alias}
            </Checkbox>
          ))}
        </div>
      }
    />
  );
};

DayCruise.defaultProps = {
  error: undefined,
  rangeDays: {},
};
