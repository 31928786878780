import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { setCookie } from 'nookies';
import { StorageNames, StorageManager } from '../lib/StorageManager';
import { favoritesNotify } from '../resources/notifications';
import { yandexDataLayer } from '../lib/yandexDataLayer';

export class CartStore {
  root;
  items;

  constructor(root) {
    this.root = root;
    this.items = StorageManager.get(StorageNames.Favorite) || [];
    makeAutoObservable(this);
  }

  checkById(id) {
    return this.items.some(i => i.id === id);
  }
  addedCard(value) {
    setCookie(null, 'cart', value, {
      maxAge: 24 * 60 * 60 * 30000,
      path: '/',
    });
  }
  add(data) {
    StorageManager.set(StorageNames.Favorite, [
      ...(StorageManager.get(StorageNames.Favorite) || []),
      data,
    ]);
    this.items = StorageManager.get(StorageNames.Favorite);
    this.addedCard(
      this.items.map(i =>
        i.isSea ? `sea_cruise_${Number(i.id)}` : `cruise_${Number(i.id)}`,
      ),
    );
  }

  remove(id) {
    const filtered = (StorageManager.get(StorageNames.Favorite) || []).filter(
      i => i.id !== id,
    );
    this.items = filtered;

    // Отчистка поля в LocalStorage
    if (filtered.length < 1) {
      StorageManager.delete(StorageNames.Favorite);
      this.addedCard(
        this.items.map(i =>
          i.isSea ? `sea_cruise_${Number(i.id)}` : `cruise_${Number(i.id)}`,
        ),
      );
      return;
    }

    StorageManager.set(StorageNames.Favorite, filtered);
    this.addedCard(
      this.items.map(i =>
        i.isSea ? `sea_cruise_${Number(i.id)}` : `cruise_${Number(i.id)}`,
      ),
    );
  }

  toggle(data) {
    const { id } = data;
    const check = this.checkById(id);

    // Уведомление
    const { method, message } = favoritesNotify(!check);

    toast[method](message);
    if (check) {
      this.remove(id);
    } else {
      this.add(data);
    }

    // Яндекс метрика - Добавление или удаление из корзины
    yandexDataLayer(check ? 'remove' : 'add', {
      id: data.id,
      name: data.title,
      price: data.price,
      category: data.category,
    });
  }

  clearAll() {
    StorageManager.delete(StorageNames.Favorite);
    this.items = StorageManager.get(StorageNames.Favorite) ?? [];
    this.addedCard(
      this.items.map(i =>
        i.isSea ? `sea_cruise_${Number(i.id)}` : `cruise_${Number(i.id)}`,
      ),
    );
  }
}
