import React, { useEffect } from 'react';
import { Else, If, Then } from 'react-if';
import { isEqual } from 'lodash';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { Formik, Form, useFormikContext } from 'formik';
import cc from 'classcat';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import styles from './searchCruises.module.sass';
import { Checkbox } from '../Checkbox';
import { SwithTypePage } from './SwithTypePage';
import { SearchCity } from '../SearchCity';
import { DatePicker } from '../DatePicker';
import { DatePickerModal } from '../DatePickerModal/DatePickerModal';
import { Button } from '../Button';
import { DayCruise } from '../DayCruise';
import { CalculateTourists } from '../CalculateTourists';
import { Tabs } from '../Tabs';
import { isClient } from '../../utils/isClient';
import { SearchInfo } from '../SearchInfo';
import { Anchor } from '../Text';
import { StorageManager, StorageNames } from '../../lib/StorageManager';
import { YaAndGaSearchLine } from '../../lib/YaAndGaHelper';
import { useSearchStore } from '../../providers/SearchStoreProvider';
import { SkeletonCover } from '../Skeleton/SkeletonCover';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';

dayjs.extend(isSameOrAfter);

// const AutoSubmitFrom = observer(() => {
//   // const store = useSearchStore();

//   // const setFilters = filters => mainResult.setFilters(filters);
//   // const clearFilters = () => mainResult.clearFilters();
//   // const { values, submitForm } = useFormikContext();

//   // useEffect(() => {
//   //   clearFilters();
//   //   setFilters(values);
//   //   submitForm();
//   // }, [values]);

//   return null;
// });

const AutoSubmitFrom = observer(() => {
  const { mainResult } = useSearchStore();
  const { values, submitForm } = useFormikContext();

  useEffect(() => {
    // const setFilters = filters => mainResult.setFilters(filters);
    mainResult.clearFilters();
    mainResult.setFilters({
      ...values,
      dates: values.dates?.filter?.(Boolean),
    });
    submitForm();
  }, [values, submitForm]);

  return null;
});

// Enum для типов круиза
export const CruiseType = {
  Rivers: 'rivers',
  Sea: 'sea',
};

Object.freeze(CruiseType);

const tabs = [
  { title: 'Реки России', value: CruiseType.Rivers },
  { title: 'Морские круизы', value: CruiseType.Sea },
];

const defaultSearchValues = {
  departure: [],
  direction: [],
  duration: [],
  dates: '',
};

const checkIsDefaultFilters = values => {
  if (!values) {
    return true;
  }

  const {
    dates,
    days,
    departure,
    direction,
    goBack,
    passengers: { adults, children, years },
  } = values;

  return (
    dates === '' &&
    (isEqual(days, {}) || days === undefined) &&
    departure.length < 1 &&
    direction.length < 1 &&
    !goBack &&
    adults === 0 &&
    children === 0 &&
    years.length < 1
  );
};

export const SearchCruises = observer(
  ({
    chooseType,
    isMobile,
    url,
    noRedirectToResult,
    startPage,
    view = 'main',
    sea,
    typeSearch,
  }) => {
    const { mainResult } = useSearchStore();
    const router = useRouter();
    const isSea = sea || router.query.type === 'sea';
    const firstLoading = mainResult?.isFirstLoading;
    const seaPage = router.pathname === '/sea';
    const isLandingView = view === 'landing';
    const isAdminView = view === 'admin';
    const getTypeMode =
      typeSearch === 'sea' ||
      router.query.type === 'sea' ||
      sea ||
      router.query.type === 'sea'
        ? CruiseType.Sea
        : CruiseType.Rivers;
    // const remember = StorageManager.get(StorageNames.Filters);
    // const lastQueryHistory = new URLSearchParams(
    //   mainResult?.removeBlankProp(mainResult.convertFiltersToUrl(remember)),
    // );
    // const isShowBtn = current =>
    //   typeof remember === 'object' &&
    //   lastQueryHistory.toString() !== '' &&
    //   !checkIsDefaultFilters(remember) &&
    //   !isEqual(remember, current);

    const form = React.useRef();
    const info = React.useRef();
    const {
      type,
      start_cities,
      visit_cities,
      cruise_length,
      cruise_capacity,
      children_capacity,
      years_capacity,
      return_cruise,
      cruise_dates,
    } = router.query;
    const toArray = str => str?.split(',').filter(Boolean) ?? [];

    let oldScrollY = 0;
    const formPosition = form.current?.offsetTop;
    const scrollPosition = () =>
      window.pageYOffset || document.documentElement.scrollTop;
    const containHide = () => info.current?.classList.contains(styles.hide);

    if (isClient && !startPage) {
      window.addEventListener('scroll', () => {
        if (
          scrollPosition() > formPosition &&
          scrollPosition() < oldScrollY &&
          containHide()
        ) {
          info.current?.classList.remove(styles.hide);
        } else if (scrollPosition() < formPosition && !containHide()) {
          info.current?.classList.add(styles.hide);
        }
        if (scrollPosition() > oldScrollY && !containHide()) {
          info.current?.classList.add(styles.hide);
        }
        oldScrollY = scrollPosition();
      });
    }

    // useEffect(() => {
    //   console.log(router.query, 'router.query');
    //   // if (mainResult.hasQuery) {
    //   mainResult.setParamsFromUrl();
    //   mainResult.load();
    //   // }
    //   mainResult.setUrlParams(true);
    // }, [mainResult.filters]);

    useEffect(() => {
      if (router.pathname === '/' || router.pathname === '/sea') {
        mainResult.setParamsFromUrl();
        mainResult.load();
      }
    }, []);

    return (
      <div>
        <div className={styles.hide} ref={info}>
          <SearchInfo
            start_cities={start_cities}
            visit_cities={visit_cities}
            cruise_dates={cruise_dates}
            cruise_capacity={cruise_capacity}
            children_capacity={children_capacity}
            cruise_length={cruise_length}
            innerRef={form}
            isMobile={isMobile}
            isSea={isSea}
          />
        </div>
        <Formik
          initialValues={{
            type:
              typeSearch === 'sea' || router.query.type === 'sea' || isSea
                ? CruiseType.Sea
                : CruiseType.Rivers,
            goBack: return_cruise === '1',
            departure: toArray(start_cities),
            direction: toArray(visit_cities),
            duration: toArray(cruise_length),
            passengers: {
              adults: cruise_capacity ? parseInt(cruise_capacity, 10) : 0,
              children: children_capacity ? parseInt(children_capacity, 10) : 0,
              years: years_capacity
                ? years_capacity?.split(',').map(i => parseInt(i, 10))
                : [],
            },
            // dates: cruise_dates?.split(',').map(date => new Date(date)) ?? '',
            dates:
              cruise_dates
                ?.split(',')
                .map(date => new Date(date))
                .filter(date => !dayjs().isSameOrAfter(date)) ?? '',
            dep: mainResult?.departureCities ?? [],
            dir: mainResult?.directionsCites ?? [],
            days: mainResult?.days ?? {},
            page: mainResult?.page,
            isLoading: mainResult?.isLoading,
          }}
          onSubmit={async values => {
            // Запоминаем фильтры, если значение values не дефолтное
            if (!checkIsDefaultFilters(values)) {
              StorageManager.set(StorageNames.Filters, {
                ...values,
                dates: values.dates?.filter?.(Boolean),
              });
            }
            // clearFilters();
            // await mainResult.load();
          }}
        >
          {({
            // <Observer>
            values,
            errors,
            touched,
            resetForm,
            isSubmitting,
            setValues,
          }) => (
            <>
              {!firstLoading ? (
                <>
                  {isClient && chooseType && (
                    <>
                      <div className={styles.tabs}>
                        <SwithTypePage />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className={styles.tabs}>
                  <div>
                    <SkeletonCover width={348} height={58} />
                  </div>
                </div>
              )}
              <Form className={cc([styles.search, styles[view]])}>
                <AutoSubmitFrom />
                <div className={styles.one} ref={form}>
                  <SearchCity
                    id="departure"
                    label="Город отправления"
                    firstLoading={firstLoading}
                    cities={mainResult?.departureCities}
                    value={values.departure}
                    loading={mainResult?.isLoading}
                    disabled={mainResult?.isLoading}
                    metric={'main_search_click_on_departure_city_field'}
                    view={view}
                    error={touched.departure && errors.departure}
                    onClickField={() => {
                      YaAndGaSearchLine('click_field_departure');
                    }}
                    onClearButtonClick={() => {
                      YaAndGaSearchLine('click_clear_city_departure');
                      mainResult.triggerSearch();
                    }}
                    actionOnClick={() => {
                      mainResult.triggerSearch();
                    }}
                    actions={
                      <Checkbox
                        name="goBack"
                        view={view}
                        onClick={() => {
                          mainResult.triggerSearch();
                          YaAndGaSearchLine(
                            !values.goBack
                              ? 'selected_checkbox_with_return_in_city_departure'
                              : 'selected_checkbox_with_return_in_city_departure',
                          );
                        }}
                      >
                        С возвращением
                      </Checkbox>
                    }
                  />
                </div>
                <div className={styles.two}>
                  <SearchCity
                    id="direction"
                    label="Направление"
                    metric={'main_search_click_on_destination_field'}
                    firstLoading={firstLoading}
                    cities={mainResult?.directionsNames}
                    disabled={mainResult?.isLoading}
                    loading={mainResult?.isLoading}
                    value={values.direction}
                    error={touched.direction && errors.direction}
                    onClickField={() => {
                      YaAndGaSearchLine('click_field_arrival');
                    }}
                    onClearButtonClick={() => {
                      YaAndGaSearchLine('click_clear_city_arrival');
                      mainResult.triggerSearch();
                    }}
                    actionOnClick={() => {
                      mainResult.triggerSearch();
                    }}
                    view={view}
                  />
                </div>
                <DayCruise
                  label="Дней в круизе"
                  id="duration"
                  metric={'main_search_click_on_days_in_cruise_field'}
                  firstLoading={firstLoading}
                  rangeDays={mainResult?.days}
                  loading={mainResult?.isLoading}
                  disabled={mainResult?.isLoading}
                  value={values.duration}
                  error={touched.duration && errors.duration}
                  view={view}
                  onClickField={() => {
                    YaAndGaSearchLine('click_field_days_in_cruise');
                  }}
                  actionOnClick={() => {
                    mainResult.triggerSearch();
                  }}
                  onClearButtonClick={() => {
                    YaAndGaSearchLine('click_clear_days_in_cruise');
                    mainResult.triggerSearch();
                  }}
                />
                {isMobile ? (
                  <DatePickerModal
                    id="dates"
                    label="Даты отправления"
                    metric={'main_search_click_on_departure_date_field'}
                    firstLoading={firstLoading}
                    minDate={mainResult?.minDate}
                    maxDate={mainResult?.maxDate}
                    disabledDate={mainResult?.disabledDate}
                    value={values.dates}
                    loading={mainResult?.isLoading}
                    error={touched.dates && errors.dates}
                    styleView={view}
                    isBodyRight={!isAdminView}
                    last
                    actionOnChange={() => {
                      mainResult.triggerSearch();
                    }}
                    onClickField={() => {
                      YaAndGaSearchLine(
                        'click_field_departure_dates_in_calendar',
                      );
                    }}
                  />
                ) : (
                  <DatePicker
                    id="dates"
                    label="Даты отправления"
                    metric={'main_search_click_on_departure_date_field'}
                    firstLoading={firstLoading}
                    minDate={mainResult?.minDate}
                    maxDate={mainResult?.maxDate}
                    disabledDate={mainResult?.disabledDate}
                    value={values.dates}
                    loading={mainResult?.isLoading}
                    error={touched.dates && errors.dates}
                    styleView={view}
                    isBodyRight={!isAdminView}
                    last
                    actionOnChange={() => {
                      mainResult.triggerSearch();
                    }}
                    onCloseAction={() => {
                      YaAndGaSearchLine('click_clear_dates_in_calendar');
                    }}
                    onClickField={() => {
                      YaAndGaSearchLine(
                        'click_field_departure_dates_in_calendar',
                      );
                    }}
                  />
                )}

                <div className={styles.five}>
                  <CalculateTourists
                    id="passengers"
                    label="Туристы"
                    metric={'main_search_click_on_tourists_field'}
                    firstLoading={firstLoading}
                    value={values}
                    loading={mainResult?.isLoading}
                    disabled={
                      seaPage || type === CruiseType.Sea || mainResult.isLoading
                    }
                    error={touched.passengers && errors.passengers}
                    passengers={values.passengers}
                    view={view}
                    onClickField={() => {
                      YaAndGaSearchLine('click_field_tourists');
                    }}
                    actionOnClick={() => {
                      mainResult.triggerSearch();
                    }}
                    actionOnChildren={() => {
                      mainResult.triggerSearch();
                    }}
                  />
                </div>
                {!isAdminView && (
                  <If condition={isLandingView}>
                    <Then>
                      <div
                        className={cc([
                          styles.six,
                          { [styles.landing]: isLandingView },
                        ])}
                      >
                        <a
                          className={cc([styles.landingSearch, styles.inGrid])}
                          href={`/result?${new URLSearchParams(
                            mainResult?.removeBlankProp(mainResult.params) ||
                              {},
                          ).toString()}`}
                        >
                          <IconSVG
                            name={'common/search-white'}
                            width={18}
                            height={18}
                          />
                          <span>Начать поиск круиза</span>
                        </a>
                      </div>
                    </Then>
                    <Else>
                      <div className={styles.six}>
                        {noRedirectToResult ? (
                          <Button
                            background="primary"
                            fullWidth
                            disabled={isSubmitting}
                            onClickField={() => {
                              YaAndGaSearchLine('click_search_in_search_line');
                              window.ym(
                                35441960,
                                'reachGoal',
                                'main_search_start_search',
                              );
                            }}
                          >
                            Искать
                          </Button>
                        ) : (
                          <Link
                            href={{
                              pathname: url ? `${url}/result` : 'result',
                              query: {
                                ...mainResult.removeBlankProp(
                                  mainResult.params,
                                ),
                                data:
                                  new URLSearchParams(
                                    mainResult.paramsFilters,
                                  ).toString() || '',
                              },
                            }}
                            className={styles.searchToResult}
                            disabled={isSubmitting}
                          >
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                            <a
                              role="button"
                              tabIndex="0"
                              className={cc([
                                styles.searchToResult,
                                {
                                  [styles.searchToResultDisabled]:
                                    mainResult.isLoading,
                                },
                              ])}
                              // target="_blank"
                              onClick={() => {
                                YaAndGaSearchLine(
                                  'click_search_in_search_line',
                                );
                                window.ym(
                                  35441960,
                                  'reachGoal',
                                  'main_search_start_search',
                                );
                              }}
                            >
                              {mainResult.isLoading ? (
                                <div className={styles.loading}>
                                  <Icon
                                    icon={
                                      <IconSVG
                                        name={'common/whiteLoader'}
                                        width={24}
                                        height={24}
                                      />
                                    }
                                    width={24}
                                    height={24}
                                    title="Loading"
                                  />
                                </div>
                              ) : (
                                'Искать'
                              )}
                            </a>
                          </Link>
                        )}
                      </div>
                    </Else>
                  </If>
                )}
              </Form>
              {!isAdminView && isLandingView && (
                <div className={styles.landingFooter}>
                  <a
                    className={styles.landingSearch}
                    href={`/result?${new URLSearchParams(
                      mainResult?.removeBlankProp(mainResult.params) || {},
                    ).toString()}`}
                  >
                    <IconSVG
                      name={'common/search-white'}
                      width={18}
                      height={18}
                    />
                    <span>Начать поиск круиза</span>
                  </a>
                </div>
              )}
              <If condition={isClient && startPage}>
                <Then>
                  <div className={styles.searchFooter}>
                    <Anchor
                      variant="text-6"
                      href={typeSearch === 'sea' ? '/liners' : '/ships'}
                      target="_blank"
                      onClick={() => {
                        window.ym(
                          35441960,
                          'reachGoal',
                          'main_search_click_on_link_button_or_start_search_by_ships',
                        );
                      }}
                    >
                      Или начните поиск по{' '}
                      {typeSearch === 'sea' ? 'лайнерам' : 'теплоходам'} →
                    </Anchor>
                    {/* {isShowBtn(values) && ( */}
                    {/*  <Anchor */}
                    {/*    variant="text-6" */}
                    {/*    href={`/result?${lastQueryHistory.toString()}`} */}
                    {/*    className={styles.showLastHistory} */}
                    {/*  > */}
                    {/*    Показать предыдущую историю поиска */}
                    {/*  </Anchor> */}
                    {/* )} */}
                  </div>
                </Then>
              </If>
            </>
            // </Observer>
          )}
        </Formik>
      </div>
    );
  },
);

SearchCruises.defaultProps = {
  view: 'main',
};
