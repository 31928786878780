import { oldApi } from '../lib/api';

export class SearchApi {
  async getList(params, signal, specialSort) {
    return (await specialSort)
      ? oldApi.post(
          'Booking2019/get_cruis_list_five_friday/new_river',
          {
            ...params,
          },
          {
            signal,
          },
        )
      : oldApi.post(
          `Booking2019/get_cruis_list/${
            params.type === 'sea' ? 'sea' : 'new_river'
          }`,
          {
            ...params,
          },
          {
            signal,
          },
        );
  }

  async getSeaList(params) {
    return await oldApi.post('Booking2019/get_cruis_list/sea', params);
  }
}
