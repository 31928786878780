import React from 'react';
import styles from './content.module.sass';

export const Content = ({ children, isBlog, showTopContent }) => (
  <div className={!showTopContent ? styles.wrapper : styles.wrapperBanner}>
    <div className="">
      <div className={isBlog ? styles.innerWrapperBlog : styles.innerWrapper}>
        {children}
      </div>
    </div>
  </div>
);
