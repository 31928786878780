import axios from 'axios';
import { interceptorSwitch } from './ssrLogger';

const prefix = 'api/v1';

const factoryApi = (headers = {}) =>
  axios.create({
    baseURL: `/${prefix}/`,
    transformRequest: [data => new URLSearchParams(data)],
    headers,
  });

export const apiBff = axios.create();

export const api = factoryApi('/');

export const oldApi = axios.create(
  process.env.NODE_ENV === 'production'
    ? {
        baseURL: `/${prefix}/`,
        transformRequest: [data => new URLSearchParams(data)],
      }
    : {
        baseURL: `https://kruiz.online/${prefix}/`,
        transformRequest: [data => new URLSearchParams(data)],
      },
);

export const oldApiTest = axios.create(
  process.env.NODE_ENV === 'production'
    ? {
        baseURL: `/${prefix}/`,
        transformRequest: [data => new URLSearchParams(data)],
      }
    : {
        baseURL: `https://test.kruiz.tech/${prefix}/`,
        transformRequest: [data => new URLSearchParams(data)],
      },
);

export const apiProxy = axios.create(
  process.env.NODE_ENV === 'production'
    ? {
        baseURL: `http://${process.env.NEXT_PUBLIC_INTERNAL_API_URL}/${prefix}/`,
      }
    : {
        baseURL: `https://kruiz.online/${prefix}/`,
        withCredentials: true,
      },
);

apiProxy.interceptors.response.use(
  response => {
    interceptorSwitch(response);
    return response;
  },
  error => Promise.reject(error),
);
